<template>
    <div class="logBox item-b" v-loading="showLoad" element-loading-text="拼命加载中">
        <FormItem :formList="formList" :btn="btn" @search="onSearch" @export="onExport" @reset="onReset" :btnObj="btnObj" :search="search"/>
        <Table ref="table" :tableList="tableList" :tableData="tableData" @operation="onHandle" @currentNum="current" :total="total"/>
    </div>
</template>

<script>
import axios from '@/request'
import axiosT from 'axios'
import moment from 'moment'
// import { delete } from 'vue/types/umd'
export default {
    name: "Log",
    data(){
      return{
        showLoad:true,
        formList:[
          {
            props:'userName',
            el:'el-input',
            label:'请输入用户名',
            placeholder:'请输入用户名'
          },
          {
            props:'datetime',
            el:'el-date-picker',
            label:'',
            placeholder:'选择日期时间'
          },
        ],
        btnObj:{
          search:'查询',
          export:'导出',
          reset:'重置'
        },
        btn:'search,export,reset',
        tableList:[
          {
            prop:'index',
            label:'序号',
            width:''
          },
          {
            prop:'createTime',
            label:'操作时间',
            width:''
          },
          {
            prop:'userName',
            label:'用户名',
            width:''
          },
          {
            prop:'roleName',
            label:'角色名',
            width:''
          },
          {
            prop:'departmentName',
            label:'所属部门',
            width:''
          },
          {
            prop:'action',
            label:'操作模块',
            width:''
          },
          {
            prop:'result',
            label:'操作内容',
            width:''
          },
        ],
        tableData:[],
        page:1,
        size:10,
        total:0,
        search: {
          userName:''
        },
      }
    },
  mounted() {
      this.getData(this.page,this.size,this.search)
  },
  methods:{
      getData(page,size,val){
        this.showLoad=true
        axios.get(`/rainLog/page/${page}/${size}`,{
          params:{
            ...val
          }
        }).then(res => {
          this.tableData = res.list
          this.total = res.total
          this.showLoad=false
        })
      },
      onSearch(val){
        this.search = val
        if(val.datetime){
        this.search.startTime=val.datetime[0]
        this.search.endTime=val.datetime[1]
        }
        delete this.search.datetime
        this.getData(this.page,this.size,val)
      },
      onExport(val){
        this.$export({
          url:'/rainLog/export',
          method: 'get',
          params:{...this.search},
          responseType:'blob'
        }).then(res=>{
          const link = document.createElement('a')
          let blob = new Blob([res], { type: 'application/vnd.ms-excel' })
          let objectUrl = URL.createObjectURL(blob)
          link.href = objectUrl
          link.download = '操作日志.xlsx'
          link.click()
          URL.revokeObjectURL(objectUrl) // 释放内存
        })
      },
      onReset(val){
          this.$refs.table.lastEmittedPage()
          this.getData(1,10,val)
      },
      // 表格操作按钮
      onHandle(data){
        // opVal ：执行表格编辑删除等操作
      },
      // 分页事件
      current(val){
        this.page = val
        this.getData(this.page,this.size,{})
      }
    }
}
</script>

<style lang="scss" scoped>
.logBox{
    width: 100%;
    background: #ffffff;
}
</style>
